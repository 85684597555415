import styled, { css } from "styled-components";

export const Overline = styled.span`
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.color.text};

  ${({ link }) =>
    link &&
    css`
      position: relative;
      cursor: pointer;
      z-index: 1;
      text-decoration: none;
      font-weight: 500;

      &.active,
      :hover {
        ::before {
          height: calc(100% + 4px);
        }
      }

      ::before {
        position: absolute;
        bottom: -2px;
        left: -2px;
        content: "";
        height: 2px;
        width: calc(100% + 4px);
        background-color: ${({ theme }) => theme.color.highlight};
        z-index: -1;
        transition: height 0.2s ease-in-out;
      }
    `};

  ${({ line }) =>
    line &&
    css`
      position: relative;
      margin-left: 40px;

      ::after {
        position: absolute;
        top: calc(50% - 0.5px);
        left: calc(-32px - 8px);
        content: "";
        height: 1px;
        width: 32px;
        background-color: ${({ theme }) => theme.color.text};
      }
    `};
`;
