export const routes = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Oferta",
    path: "/oferta",
  },
  {
    name: "Realizacje",
    path: "/realizacje",
  },
  {
    name: "Kontakt",
    path: "/kontakt",
  },
];
