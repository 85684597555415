import React, { useState } from "react";
import { Link } from "gatsby";
import { Overline } from "components/Overline/Overline";
import DarkModeIcon from "assets/icons/darkmode.inline.svg";
import LightModeIcon from "assets/icons/lightmode.inline.svg";
import HamburgerIcon from "assets/icons/hamburger.inline.svg";
import { useDarkMode } from "usehooks-ts";
import { AnimatePresence, useMotionValueEvent, useScroll } from "framer-motion";
import { NavigationMobile } from "./NavigationMobile";
import { routes } from "./nav.config";
import {
  Container,
  StyledNavigation,
  Wrapper,
  StyledLogo,
  StyledDivider,
  StyledListItem,
  StyledThemeButton,
  StyledList,
  StyledBurgerButton,
  StyledLogoLink,
} from "./Navigation.styles";

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { scrollYProgress } = useScroll();

  const [isAnchored, setIsAnchored] = useState(true);

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const _isAnchored = latest === 0;
    if (isAnchored !== _isAnchored) setIsAnchored(_isAnchored);
  });

  const { isDarkMode, toggle } = useDarkMode();

  const handleToggleNavigation = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Container className="container" {...{ isAnchored }}>
        <StyledLogoLink to="/" aria-label="Home">
          <StyledLogo />
        </StyledLogoLink>
        <Wrapper>
          <StyledNavigation>
            <StyledBurgerButton
              className="d-md-none"
              onClick={handleToggleNavigation}
            >
              <Overline>Menu</Overline>
              <div className={`menu ${isOpen ? "opened" : ""}`}>
                <HamburgerIcon />
              </div>
            </StyledBurgerButton>

            <StyledList className="d-none d-md-flex">
              {routes.map(({ name, path }) => (
                <StyledListItem key={name}>
                  <Overline as={Link} to={path} activeClassName="active" link>
                    {name}
                  </Overline>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledNavigation>
          <StyledDivider />
          <StyledThemeButton
            type="button"
            onClick={toggle}
            aria-label={`Tryb ${isDarkMode ? "dzień" : "noc"}`}
          >
            {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
            <Overline className="d-none d-sm-block">
              {isDarkMode ? "Dzień" : "Noc"}
            </Overline>
          </StyledThemeButton>
        </Wrapper>
      </Container>
      <AnimatePresence>
        {isOpen ? <NavigationMobile {...{ handleToggleNavigation }} /> : null}
      </AnimatePresence>
    </>
  );
};
