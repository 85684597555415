const FONT = {
  font: {
    family: {
      montserrat: '"Montserrat", sans-serif',
      cormorant: '"Cormorant Garamond", serif',
    },
  },
};

const MQ = {
  mq: {
    mobile: "@media (min-width: 576px)",
    tablet: "@media (min-width: 768px)",
    desktop: "@media (min-width: 1024px)",
    bigDesktop: "@media (min-width: 1280px)",
    huge: "@media(min-width: 1440px)",
  },
};

export const theme = {
  light: {
    color: {
      gray: "rgb(209, 209, 214)",
      gray2: "rgb(199, 199, 204)",
      gray3: "rgb(174, 174, 178)",
      background: "rgb(255, 255, 255)",
      backgroundGray: "rgb(242, 242, 247)",
      backgroundAlpha: "rgba(255, 255, 255, .6)",
      text: "rgba(0, 0, 0, .87)",
      textSecondary: "rgba(28, 28, 30, .87)",
      textDisabled: "rgba(0, 0, 0, .38)",
      white: "rgba(255, 255, 255, .87)",
      primary: "#54433A",
      primaryHover: "#433229",
      navigationFullscreen: "rgba(255, 255, 255, .6)",
      highlight: "#BBA79C",
      highlightHover: "#AA968B",
      error: "rgb(255, 59, 48)",
      light: "#BBA79C",
      modal: "rgba(0, 0, 0, .13)",
    },
    ...FONT,
    ...MQ,
  },
  dark: {
    color: {
      gray: "rgb(58, 58, 60)",
      gray2: "rgb(72, 72, 74)",
      gray3: "rgb(99, 99, 102)",
      background: "rgb(28, 28, 30)",
      backgroundGray: "rgb(44, 44, 46)",
      backgroundAlpha: "rgba(0, 0, 0, .6)",
      text: "rgba(255, 255, 255, .87)",
      textSecondary: "rgba(242, 242, 247, .87)",
      textDisabled: "rgba(255, 255, 255, .38)",
      white: "rgba(255, 255, 255, .87)",
      primary: "#BBA79C",
      primaryHover: "#AA968B",
      navigationFullscreen: "rgba(28, 28, 30, .6)",
      highlight: "#54433A",
      highlightHover: "#433229",
      error: "rgb(255, 69, 58)",
      light: "#BBA79C",
      modal: "rgba(255, 255, 255, .1)",
    },
    ...FONT,
    ...MQ,
  },
};
