import styled, { css } from "styled-components";
import { Overline } from "components/Overline/Overline";
import Logo from "assets/icons/logo.inline.svg";
import { Link } from "gatsby";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 50%;
  padding: 8px 16px;
  width: calc(100% - 32px);
  background-color: ${({ theme }) => theme.color.background};
  z-index: 1000;
  background-color: ${({ theme }) => theme.color.navigationFullscreen};
  backdrop-filter: blur(8px);
  border: 1px transparent solid;
  transition: max-width 0.3s ease-in-out, border-color 0.3s ease-in-out,
    translate 0.3s ease-in-out;

  ${({ theme }) => theme.mq.mobile} {
    width: 100%;
  }

  ${({ theme }) => theme.mq.tablet} {
    padding: 8px 32px;
  }

  ${({ isAnchored, theme }) =>
    isAnchored
      ? css`
          max-width: min(calc(100% - 32px), 1720px);
          translate: -50% 48px;
        `
      : css`
          border-color: ${theme.color.gray};
          translate: -50% 24px;
        `};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const StyledNavigation = styled.nav`
  margin-left: auto;
  position: relative;
`;

export const StyledList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  text-align: center;
  gap: 32px;
`;

export const StyledListItem = styled.li`
  a {
    text-decoration: none;
    font-size: 14px;
  }
`;

export const StyledDivider = styled.span`
  height: 24px;
  width: 1px;
  background-color: ${({ theme }) => theme.color.gray};
  margin: 0 16px;

  ${({ theme }) => theme.mq.tablet} {
    margin: 0 32px;
  }
`;

export const StyledThemeButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  cursor: pointer;

  ${Overline} {
    font-size: 14px;
    font-weight: 500;
  }

  path {
    fill: ${({ theme }) => theme.color.text};
  }
`;

export const StyledBurgerButton = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  gap: 2px;
  height: 24px;
  margin: -10px -16px -10px 0;
  padding: 22px 16px 22px 30px;

  ${Overline} {
    line-height: 16px;
  }

  .menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
  }
  .line {
    fill: none;
    stroke: ${({ theme }) => theme.color.text};
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
`;

export const StyledLogoLink = styled(Link)`
  display: flex;
`;

export const StyledLogo = styled(Logo)`
  path {
    fill: ${({ theme }) => theme.color.primary};
  }

  width: 130px;

  ${({ theme }) => theme.mq.tablet} {
    width: auto;
  }

  .path {
    stroke: ${({ theme }) => theme.color.primary};
  }
`;

export const NavFullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.navigationFullscreen};
  backdrop-filter: blur(8px);
  padding: 69px 16px 24px;
  z-index: 900;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  ul {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-flow: column nowrap;
    list-style-type: none;
    padding: 0;
    margin: 32px 0 8px;
    width: 100%;
    height: 100%;
    gap: 24px;

    li {
      transform: translateX(-50px);
      opacity: 0;

      a {
        text-decoration: none;
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        position: relative;
        width: 100%;

        &.active {
          ${Overline} {
            color: ${({ theme }) => theme.color.primary};
          }

          svg {
            fill: ${({ theme }) => theme.color.primary};
            transform: rotate(180deg);
          }
        }

        ${Overline} {
          font-size: 48px;
          text-align: left;
        }

        svg {
          fill: ${({ theme }) => theme.color.text};
          height: 48px;
          width: 48px;
        }
      }
    }
  }
`;
