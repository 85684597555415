import React, { useEffect, useRef } from "react";
import { stagger, useAnimate, usePresence } from "framer-motion";
import { Link } from "gatsby";
import ArrowRightIcon from "assets/icons/arrow_right.inline.svg";
import { Overline } from "components/Overline/Overline";
import { routes } from "./nav.config";
import { NavFullScreen } from "./Navigation.styles";

export const NavigationMobile = ({ handleToggleNavigation }) => {
  const listRef = useRef();
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate([
          [
            scope.current,
            {
              opacity: 1,
            },
            {
              duration: 0.4,
              ease: "easeOut",
            },
          ],
          [
            listRef.current.children,
            {
              opacity: 1,
              transform: "translateX(0)",
            },
            { delay: stagger(0.1), at: "-0.1" },
          ],
        ]);
      };

      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate([
          [
            listRef.current.children,
            {
              opacity: 0,
              transform: "translateX(-50px)",
            },
            { delay: stagger(0.1, { from: "last" }), at: "<" },
          ],
          [
            scope.current,
            { opacity: 0 },
            {
              duration: 0.4,
              ease: "easeIn",
              at: "-0.1",
            },
          ],
        ]);
        safeToRemove();
      };

      exitAnimation();
    }
  }, [isPresent]);

  return (
    <NavFullScreen ref={scope}>
      <ul ref={listRef}>
        {routes.map(({ name, path }, i) => (
          <li key={name}>
            <Link
              to={path}
              onClick={handleToggleNavigation}
              activeClassName="active"
            >
              <Overline>{name}</Overline>
              <ArrowRightIcon />
            </Link>
          </li>
        ))}
      </ul>
    </NavFullScreen>
  );
};
