import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
  }
  
  body {
    margin: 0;
    cursor: default;
    color: ${({ theme }) => theme.color.text};
    background-color: ${({ theme }) => theme.color.background};

    font-size: 0.8333333333333334vw;

    @media screen and (min-width: 1920px) {
      font-size: 16px;
    }

    @media screen and (max-width: 1250px) {
      font-size: 12px;
    }
  }
`;
