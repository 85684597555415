import styled from "styled-components";

export const StyledMain = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding-top: 141px;

  ${({ theme }) => theme.mq.tablet} {
    padding-top: 250px;
  }
`;
